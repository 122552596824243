import { makeStyles } from '@material-ui/core/styles';
import { WHITE, PRIMARY, BLUE_LIGHT } from '@theme_color';
import { Centering } from '@theme_mixins';
import { GRAY_400 } from '@src_theme/colors';

const useStyles = makeStyles((theme) => ({
    caraousel: {
        width: '100%',
        height: 'calc(100% + 25px)',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            // height: 'auto',
            height: 'calc(100% + 25px)',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100vw',
        },
    },
    arrow: {
        fontSize: '1.5rem',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        position: 'absolute',
        ...Centering,
        padding: 10,
        borderRadius: '20px',
        textAlign: 'center',
        paddingLeft: 10,
        top: 'calc(40% - 1rem)',
        width: 40,
        height: 40,
        cursor: 'pointer',
        color: GRAY_400,
        '&:hover': {
            backgroundColor: PRIMARY,
            color: WHITE,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    leftArrow: {
        left: 5,
    },
    leftArrowCenter: {
        left: -10,
    },
    rightArrow: {
        right: -10,
        [theme.breakpoints.down('sm')]: {
            right: 20,
        },
    },
    viewAllContainer: {
        marginLeft: '1.5vw',
        position: 'absolute',
        top: 'calc(50% - 1rem)',
        display: 'flex !important',
        alignItems: 'center',
    },
    viewAllArrow: {
        background: `${BLUE_LIGHT} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 3px 6px #00000029',
        ...Centering,
        borderRadius: '20px',
        textAlign: 'center',
        width: '20px !important',
        height: 20,
        cursor: 'pointer',
        color: WHITE,
        '& .MuiSvgIcon-root': {
            fontSize: '.75rem',
        },
    },
}));

export default useStyles;
