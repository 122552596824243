/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import Slider from 'react-slick';
import RightArrowIcon from '@material-ui/icons/ArrowForwardIos';
import { formatPrice } from '@helper_currency';
import useStyles from './style';

const AlertView = (props) => {
    const {
        show, setShow, data, t,
    } = props;
    let sliderRef = React.createRef();
    const styles = useStyles();

    const renderMessage = (item) => {
        if (item.couponType === 'percent') {
            return `${t('common:promoSlick:youHave')} ${item.value}% ${t('common:promoSlick:withCode')} ${item.couponCode}`;
        }
        return `${t('common:promoSlick:youHave')} ${formatPrice(item.value, 'IDR')} ${t('common:promoSlick:withCode')} ${item.couponCode}`;
    };
    // eslint-disable-next-line no-unused-vars
    const [slideIndex, setIndex] = React.useState(0);
    const [count, setCount] = React.useState(0);

    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        rows: 1,
        slidesPerRow: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        className: 'slider',
        centerMode: true,
        centerPadding: '0px',
        afterChange: () => setCount(count + 1),
        beforeChange: (current, next) => setIndex(next),
    };
    return (
        <>
            <div className={classNames(styles.alertCoupon, !show && 'hide')}>
                <div>
                    <span className={styles.buttonClose} onClick={() => setShow(false)}>
                        x
                    </span>
                </div>
                <div className={classNames(styles.caraousel, !show && 'hide')}>
                    <Slider ref={(slider) => sliderRef = slider} {...settings}>
                        {
                            data && data.length > 0 && data.map((item, idx) => (
                                <span classNames={classNames(styles.alertText)} key={idx}>
                                    {renderMessage(item)}
                                </span>
                            ))
                        }
                    </Slider>
                    <div className={classNames(styles.arrow, styles.rightArrow)} onClick={() => sliderRef.slickNext()}>
                        <RightArrowIcon fontSize="inherit" />
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    .hide {
                        height: 0;
                    }
                `}
            </style>
        </>
    );
};

export default AlertView;
