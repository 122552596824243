/* eslint-disable consistent-return */
import { getVendor } from '@helper_cookies';
import React from 'react';
import CarouselV2 from '@commons/CarouselV2';
import ErrorInfoComponent from '@core_modules/home/pages/default/components/ErrorInfo';
import { getLastViewedProducts } from '@modules/recently_viewed/service/graphql';
import Link from 'next/link';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import ProductItem from '../ProductCard';
import useHomeStyles from '../style';
import { SkeletonCard } from '../CategoryTabs';

const TopSellerProductTab = ({ tabsDataProduct, storeConfig }) => {
    const homeStyles = useHomeStyles();
    return (
        <div>
            <div className={homeStyles.widgetTitleContainer}>
                <h2 className={homeStyles.titleWidget}>Lihat Kembali</h2>
                <Link href="/recently-viewed">
                    <a className={homeStyles.allCategoryLink}>
                        <span>Lihat Semua</span>
                        <KeyboardArrowRightIcon className={homeStyles.linkIcon} />
                    </a>
                </Link>
            </div>
            {tabsDataProduct.map((products, key) => (
                <CarouselV2 key={key}>
                    {products.map((product) => (
                        <ProductItem
                            key={product.id}
                            {...product}
                            className={homeStyles.productCardSlide}
                            imgProps={{ className: homeStyles.productCardSlideImg }}
                            storeConfig={storeConfig}
                        />
                    ))}
                </CarouselV2>
            ))}
        </div>
    );
};

const TopSellerRenderWithVendor = ({
    vendor,
    t,
    storeConfig,
}) => {
    const config = {
        vendorId: vendor,
    };

    const { loading, data } = getLastViewedProducts(config, {
        variables: {
            pageSize: 10,
            currentPage: 1,
        },
        fetchPolicy: 'no-cache',
        context: {
            request: 'internal',
        },
    });

    if (loading) {
        return (
            <div style={{ display: 'flex', gap: '16px' }}>
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
            </div>
        );
    }

    if (data?.getLastViewedProducts?.items?.length > 0) {
        const tabsDataProduct = [data.getLastViewedProducts.items];
        return (
            <TopSellerProductTab
                tabsDataProduct={tabsDataProduct}
                t={t}
                storeConfig={storeConfig}
            />
        );
    }

    return null;
};

const ViewAgainRender = (props) => {
    const { t, storeConfig } = props;

    const vendor = getVendor();
    if (vendor) {
        return <TopSellerRenderWithVendor storeConfig={storeConfig} vendor={vendor} ErrorInfo={ErrorInfoComponent} t={t} />;
    }

    return null;
};

export default ViewAgainRender;
