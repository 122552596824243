import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'unset !important',
        '& .slider-container': {
            width: '100%',
            height: 'auto',
            lineHeight: 0,
            '& .slide': {
                '& p': {
                    textAlign: 'center',
                },
            },
            '& img': {
                width: '100%',
                height: 'auto',
            },
        },
    },
    containerLoading: {
        height: 45,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default useStyles;
