// eslint-disable-next-line import/prefer-default-export
export const handleTabLabel = (string) => {
    const arrayString = string.split(' ');
    if (arrayString.length > 1) {
        const getFirstWord = arrayString.slice(0, 1).join(' ');
        const getSecondWord = string.replace(`${getFirstWord} `, '');

        return (
            <>
                {getFirstWord}
                <br />
                {getSecondWord}
            </>
        );
    }
    return arrayString[0];
};
