import { makeStyles } from '@material-ui/core/styles';
import Link from 'next/link';
import { GRAY_800 } from '@src_theme/colors';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: '16px',
        paddingBottom: 24,
        '@media (max-width: 768px)': {
            display: 'block',
        },
    },
    title: {
        fontSize: 28,
        color: GRAY_800,
        fontWeight: 600,
        margin: 0,
    },
});

export default function WidgetHeader({ children, title, titleLink = '' }) {
    const styles = useStyles();

    return (
        <div className={styles.container}>
            {titleLink ? (
                <Link href={titleLink} prefetch={false}>
                    <a>
                        <h3 className={styles.title}>{title}</h3>
                    </a>
                </Link>
            ) : (
                <h2 className={styles.title}>{title}</h2>
            )}
            {children}
        </div>
    );
}
