/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Badge from '@material-ui/core/Badge';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BagIcon from '@src_theme/icons/Bag';

const useStyles = makeStyles({
    root: {
        margin: 20,
        cursor: 'pointer',
    },
});

const WithLink = ({ cartData, handleLink }) => {
    const styles = useStyles();
    return (
        <div className={styles.root} onClick={handleLink}>
            <Badge showZero badgeContent={cartData || 0}>
                <BagIcon height={24} />
            </Badge>
        </div>
    );
};

export default WithLink;
