/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-return-assign */
import React, { useState } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import LeftArrowIcon from '@material-ui/icons/ArrowBackIos';
import RightArrowIcon from '@material-ui/icons/ArrowForwardIos';
import useStyles from '@modules/commons/Slick/Caraousel/style';
import Link from 'next/link';
import Typography from '@common_typography';

const Caraousel = (props) => {
    const {
        data = [],
        xs = 767,
        sm = 1023,
        md = 1200,
        lg = 1300,
        slideXs = 1,
        slideSm = 3,
        slideMd = 4,
        slideLg = 6,
        slidesToScroll = 'default',
        showArrow = true,
        centerMode = true,
        infinite = false,
        url_path,
        urlCustom = null,
        Item,
        rows = 1,
        leftSpace = false,
        customSettings,
        responsiveCustom = {},
        t,
        rightArrowStyle = {},
        leftArrowStyle = {},
        dots = false,
        ...other
    } = props;

    const styles = useStyles();
    // eslint-disable-next-line no-unused-vars
    const [slideIndex, setIndex] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [count, setCount] = useState(1);
    let sliderRef = React.createRef();
    const settings = {
        arrows: false,
        dots,
        infinite,
        speed: 500,
        rows,
        slidesPerRow: 1,
        slidesToShow: Math.ceil((data.length < slideLg ? data.length : slideLg) / rows),
        slidesToScroll: centerMode ? 1 : slidesToScroll === 'default' ? slideLg : slidesToScroll,
        initialSlide: 0,
        className: 'slider',
        centerMode,
        afterChange: (current) => setCount(current),
        beforeChange: (current, next) => setIndex(next),
        responsive: [
            {
                breakpoint: lg,
                settings: {
                    slidesToShow: Math.ceil((data.length < slideLg ? data.length : slideLg) / rows),
                    slidesToScroll: centerMode ? 1 : slidesToScroll === 'default' ? slideLg : slidesToScroll,
                    className: 'slider',
                    centerMode,
                },
            },
            {
                breakpoint: md,
                settings: {
                    slidesToShow: Math.ceil((data.length < slideMd ? data.length : slideMd) / rows),
                    slidesToScroll: centerMode ? 1 : slidesToScroll === 'default' ? slideMd : slidesToScroll,
                    className: 'slider',
                    centerMode,
                },
            },
            {
                breakpoint: sm,
                settings: {
                    slidesToShow: Math.ceil((data.length < slideSm ? data.length : slideSm) / rows),
                    swipeToSlide: true,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '50px',
                    className: 'slider',
                },
            },
            {
                breakpoint: xs,
                settings: {
                    slidesToShow: Math.ceil((data.length < slideXs ? data.length : slideXs) / rows),
                    swipeToSlide: true,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '50px',
                    className: 'slider',
                },
            },
            responsiveCustom,
        ],
        ...customSettings,
    };
    const dataToMap = data && data.length > 0 && (infinite ? data : data.slice(0, slideLg * 3));

    React.useEffect(() => {
        sliderRef.slickGoTo(0, true);
    }, [data]);

    return (
        <div className={classNames(!infinite && 'carousel-finite', leftSpace && 'carousel-finite-lg', 'carousel', styles.caraousel)}>
            <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
                {dataToMap && dataToMap.length > 0 && dataToMap.map((item, key) => <Item key={key} {...item} {...other} />)}
                {!infinite && (
                    <div className={classNames(styles.viewAllContainer)}>
                        <Link href={urlCustom || '/[...slug]'} as={`/${url_path}`} prefetch={false}>
                            <a style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography letter="capitalize" variant="span" align="left" type="bold">
                                    {t('common:button:viewAll')}
                                </Typography>
                                <div className={classNames(styles.viewAllArrow, 'arrow-all')}>
                                    <RightArrowIcon fontSize="10" />
                                </div>
                            </a>
                        </Link>
                    </div>
                )}
            </Slider>
            {showArrow ? (
                infinite ? (
                    <>
                        <div
                            className={classNames(styles.arrow, centerMode ? styles.leftArrowCenter : styles.leftArrow, leftArrowStyle)}
                            onClick={() => sliderRef.slickPrev()}
                        >
                            <LeftArrowIcon fontSize="inherit" />
                        </div>
                        <div className={classNames(styles.arrow, styles.rightArrow, rightArrowStyle)} onClick={() => sliderRef.slickNext()}>
                            <RightArrowIcon fontSize="inherit" />
                        </div>
                    </>
                ) : (
                    <>
                        {slideIndex !== 0 ? (
                            <div
                                className={classNames(styles.arrow, centerMode ? styles.leftArrowCenter : styles.leftArrow, leftArrowStyle)}
                                onClick={() => sliderRef.slickPrev()}
                            >
                                <LeftArrowIcon fontSize="inherit" />
                            </div>
                        ) : null}
                        <div className={classNames(styles.arrow, styles.rightArrow, rightArrowStyle)} onClick={() => sliderRef.slickNext()}>
                            <RightArrowIcon fontSize="inherit" />
                        </div>
                    </>
                )
            ) : null}
            <style jsx global>
                {`
                    .carousel .slick-track {
                        margin-right: auto;
                        margin-left: auto;
                    }
                    .carousel-finite .arrow-all {
                        display: flex !important;
                    }

                    @media (max-width: 1023px) {
                        .carousel-finite .slick-track {
                            margin-left: -31vw !important;
                        }
                    }
                    @media (max-width: 767px) {
                        .carousel-finite .slick-track {
                            margin-left: -50vw !important;
                        }
                        .carousel .price__final {
                            font-size: 2.5vw;
                        }
                        .carousel .badge__container {
                            right: -15%;
                            top: -10%;
                            width: 20px;
                            height: 20px;
                        }
                        .carousel .badge__value {
                            font-size: 8px;
                            line-height: 20px;
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default Caraousel;
