import { getCmsBlocks } from '@root/src/modules/theme/services/graphql';
import { features } from '@root/swift.config';
import WidetSliderCarousel from '@root/src/modules/cms/pages/default/components';
import { getCookies } from '@helper_cookies';
import useStyles from './style';

const AlertPromo = (props) => {
    const { storeConfig, ...other } = props;
    const { key_cookies, enable } = features.globalPromo;
    const enablePromo = getCookies(key_cookies) !== ''
        ? !!getCookies(features.globalPromo.key_cookies)
        : enable;

    const [showGlobalPromo, setShowGlobalPromo] = React.useState(enablePromo);
    const styles = useStyles();

    const handleClosePromo = () => {
        setShowGlobalPromo(false);
    };

    const { data, loading } = getCmsBlocks({
        identifiers: 'weltpixel_global_promo_message',
    });

    if (loading) {
        return null;
    }

    if (showGlobalPromo && data?.cmsBlocks?.items[0]?.content) {
        return (
            <WidetSliderCarousel
                className={styles.container}
                content={data.cmsBlocks.items[0].content}
                key_cookies={key_cookies}
                backgroundColor="rgb(118, 150, 199)"
                textColor="white"
                storeConfig={storeConfig}
                showClose
                handleClose={handleClosePromo}
                {...other}
            />
        );
    }

    return null;
};

export default AlertPromo;
