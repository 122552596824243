/* eslint-disable no-underscore-dangle */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { modules, debuging } from '@config';
import { getLoginInfo } from '@helper_auth';
// import { setCookies, getCookies } from '@helper_cookies';
import { setCookies } from '@helper_cookies';
import { useTranslation } from '@i18n';
import route from 'next/router';
import React from 'react';
import { setResolver, getResolver } from '@helper_localstorage';
import useStyles from './style';
// import { useQuery } from '@apollo/client';
// import { addWishlist, addCompare } from '@modules/catalog/services/graphql';
// import { localCompare } from '@modules/theme/services/graphql/local';
import { addWishlist } from '@modules/catalog/services/graphql';
import classNames from 'classnames';
import Container from './components/Container';

import dynamic from 'next/dynamic';

const ConfigurableOpt = dynamic(() => import('@core_modules/catalog/plugin/ProductItem/components/ConfigurableProductItem'), { ssr: false });
const WeltpixelLabel = dynamic(() => import('@root/src/modules/catalog/plugin/ProductItem/components/WeltpixelLabel'), { ssr: false });

const ProductItem = (props) => {
    const {
        id, url_key = '', categorySelect, review, ImageProductView, DetailProductView, LabelView, className = '',
        imgProps, weltpixel_labels, customGtmClass = 'gtm_mitra10_cta_product', sku = '', storeConfig, ...other
    } = props;
    // const [vendorData] = other.vendor_product_data;
    const styles = useStyles();
    const { t } = useTranslation(['catalog']);
    const [feed, setFeed] = React.useState(false);
    const [spesificProduct, setSpesificProduct] = React.useState({});

    let isLogin = '';
    if (typeof window !== 'undefined') isLogin = getLoginInfo();
    const [postAddWishlist] = addWishlist();
    // const [postAddCompare] = addCompare();
    // const { data: dataCompare, client } = useQuery(localCompare);

    const handleFeed = () => {
        if (isLogin && isLogin !== '') {
            postAddWishlist({
                variables: {
                    productId: id,
                },
            })
                .then(async () => {
                    await setFeed(!feed);
                    await window.toastMessage({ open: true, variant: 'success', text: t('common:message:feedSuccess') });
                    route.push('/wishlist');
                })
                .catch((e) => {
                    window.toastMessage({
                        open: true,
                        variant: 'error',
                        text: debuging.originalError ? e.message.split(':')[1] : t('common:message:feedFailed'),
                    });
                });
        } else if (typeof window.toastMessage !== 'undefined') {
            window.toastMessage({
                open: true,
                variant: 'warning',
                text: t('catalog:wishlist:addWithoutLogin'),
            });
        }
    };

    const handleClick = async () => {
        if (storeConfig?.enabled_clickstream === '1' && typeof window !== 'undefined' && window?._swan?.productClicked) {
            // eslint-disable-next-line no-underscore-dangle
            window._swan.productClicked({ productId: sku });
        }

        const urlResolver = getResolver();
        urlResolver[`/${url_key}`] = {
            type: 'PRODUCT',
        };
        await setResolver(urlResolver);
        setCookies('lastCategory', categorySelect);
        route.push('/[...slug]', `/${url_key}`);
    };

    // const getUid = () => {
    //     const uidCompare = getCookies('uidCompare');
    //     if (uidCompare) {
    //         return uidCompare;
    //     }
    //     const newUid = `${new Date().getTime().toString(36)}${(Math.random()).toString(36).replace('.', '')}`;
    //     setCookies('uidCompare', newUid);
    //     return newUid;
    // };

    const handleCompare = () => {
        // postAddCompare({
        //     variables: {
        //         productId: id,
        //         vendorId: vendorData.vendor_id,
        //         uid: isLogin ? '' : getUid(),
        //     },
        // })
        //     .then(async () => {
        //         await window.toastMessage({ open: true, variant: 'success', text: t('common:message:compareSuccess') });
        //         client.writeQuery({ query: localCompare, data: { totalCompare: dataCompare.totalCompare + 1 } });
        //     })
        //     .catch((e) => {
        //         window.toastMessage({
        //             open: true,
        //             variant: 'error',
        //             text: debuging.originalError ? e.message.split(':')[1] : t('common:message:compareFailed'),
        //         });
        //     });
    };

    const ratingValue = review && review.rating_summary ? parseInt(review.rating_summary, 0) / 20 : 5;
    const DetailProps = {
        spesificProduct,
        handleClick,
        handleFeed,
        handleCompare,
        ratingValue,
        feed,
    };
    return (
        <>
            <Container className={classNames(className)}>
                {
                    modules.catalog.productListing.label.enabled && LabelView ? (
                        <LabelView {...other} spesificProduct={spesificProduct} />
                    ) : null
                }
                <div className={styles.imgItem}>
                    <WeltpixelLabel t={t} weltpixel_labels={weltpixel_labels} categoryLabel />
                    <ImageProductView
                        handleClick={handleClick}
                        url_key={url_key}
                        spesificProduct={spesificProduct}
                        imgProps={imgProps}
                        {...other}
                        customGtmClass={customGtmClass}
                    />
                </div>
                <div className={styles.detailItem}>
                    <DetailProductView
                        {...DetailProps}
                        {...other}
                        url_key={url_key}
                        customGtmClass={customGtmClass}
                    />
                    {modules.catalog.productListing.configurableOptions.enabled ? (
                        <ConfigurableOpt setSpesificProduct={setSpesificProduct} {...other} />
                    ) : null}
                </div>
            </Container>
        </>
    );
};

export default ProductItem;
