import * as React from 'react';
const SvgBag = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
        <path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 367" />
        <path
            fillRule="evenodd"
            d="M8.905 7.429V8H7.391A1.429 1.429 0 0 0 5.97 9.279l-.962 9.143A1.429 1.429 0 0 0 6.425 20h11.81a1.429 1.429 0 0 0 1.421-1.578l-.962-9.143A1.429 1.429 0 0 0 17.276 8h-1.514v-.571a3.429 3.429 0 0 0-6.857 0Zm3.428-2.286a2.286 2.286 0 0 0-2.285 2.286V8h4.571v-.571a2.286 2.286 0 0 0-2.286-2.286Zm-2.285 6.286a2.286 2.286 0 1 0 4.571 0v-.572a.572.572 0 0 1 1.143 0v.571a3.429 3.429 0 1 1-6.857 0v-.571a.572.572 0 0 1 1.143 0Z"
            data-name="Path 382"
        />
    </svg>
);
export default SvgBag;
