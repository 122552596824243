import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    CreatePadding, FlexColumn, CreateMargin, FlexRow,
} from '@theme_mixins';
import { GRAY_PRIMARY, PRIMARY } from '@theme_color';
import { ERROR, GRAY_100, GRAY_800 } from '@src_theme/colors';

export default makeStyles(() => ({
    container: {},
    itemContainer: {
        width: 148,
        height: 260,
        borderRadius: 8,
        position: 'relative',
        overflow: 'hidden',
        border: `1px solid ${GRAY_100}`,
        backgroundColor: 'white',
        boxShadow: `0px 1px 2px ${GRAY_100}`,
        '@media (min-width: 768px)': {
            width: 188,
            height: 316,
        },
    },
    badgesNewSales: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 5,
        borderRadius: 5,
        zIndex: 1,
        ...FlexRow,
        justifyContent: 'space-between',
        width: '100%',
        padding: 15,
    },
    imgProduct: {
        width: '100%',
        height: '100%',
    },
    detailItem: {
        padding: 10,
        paddingTop: 6,
        position: 'relative',
        '@media (min-width: 768px)': {
            padding: 12,
        },
    },
    descItem: {
        ...FlexColumn,
        gap: '8px',

        // price
        '& > :first-child': {
            fontSize: 12,
            lineHeight: 1,
            height: 29,
            display: 'flex',
            alignItems: 'flex-end',
            '@media (min-width: 768px)': {
                fontSize: 14,
            },
            '& .priceShowDisccount': {
                width: '100%',
                display: 'flex',
                flexDirection: 'row !important',
                '& > :first-child': {
                    marginRight: 'auto',
                },
            },
            '& .badge__container': {
                position: 'initial',
            },
            '& span': {
                lineHeight: 1,
                '&:first-child': {
                    paddingBottom: 5,
                    fontSize: 10,
                    '@media (min-width: 768px)': {
                        fontSize: 12,
                    },
                },
                '&:last-child': {
                    fontWeight: 600,
                    fontSize: 14,
                },
            },
        },
    },
    title: {
        fontWeight: 600,
        color: GRAY_800,
        lineHeight: 1.25,
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitLineClamp: 2,
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        fontSize: 12,
        '@media (min-width: 768px)': {
            fontSize: 14,
        },
    },
    price: {
        marginBottom: 8,
        textAlign: 'left',
        '& > span': {
            fontWeight: 600,
            fontSize: 14,
            color: ERROR,
        },
    },
    productTitle: {
        margin: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        'line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        fontSize: 14,
        fontWeight: 600,
        color: GRAY_800,
        marginBottom: 12,
    },
    productLinkButton: {
        maxWidth: '100%',
        '@media (min-width: 768px )': {
            maxWidth: 'calc(100% - 38px)',
        },
    },
    btnFeed: {
        '& svg': {
            fontSize: 18,
            color: GRAY_PRIMARY,
            fontWeight: '200',
        },
    },
    iconActive: {
        color: PRIMARY,
    },
    colorContainer: {
        ...FlexRow,
        ...CreatePadding(10, 10, 0, 0),
    },
    btnColor: {
        ...CreateMargin(0, 5, 0, 0),
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',

        // stars
        '& > :first-child': {
            height: 'min-content',
            '& .MuiButtonBase-root': {
                width: 18,
                height: 18,
                '& svg': {
                    fontSize: '16px !important',
                },
            },
        },
    },
    feedContainer: {
        position: 'absolute',
        width: '20px',
        top: '-4px',
        right: '45px',
        textAlign: 'right',
    },
    btnCompare: {
        padding: 0,
    },
    icon: {
        fontSize: 18,
        color: GRAY_PRIMARY,
        fontWeight: '200',
    },
    imgItem: {
        position: 'relative',
    },
    img: {
        // width: 184,
        width: '100%',
        height: 148,
        // aspectRatio: '1 / 1',
        borderRadius: 4,
        objectFit: 'cover',
        '@media (min-width: 768px)': {
            height: 186,
        },
    },
    ratingContainer: {
        ...FlexRow,
        gap: '4px',
        '& .rating': {
            ...FlexRow,
            '& .MuiSvgIcon-root': {
                height: '16px',
                width: '16px',
                color: '#FBBF24',
                marginRight: '4px',
            },
        },
        '& .rating-count': {
            margin: 0,
            fontSize: '12px',
        },
        '& .sold-container': {
            ...FlexRow,
            fontSize: '12px',
        },
    },
}));
