import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';
import useStyles from './style';

const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const SkeletonContent = () => {
    const styles = useStyles();
    return (
        <>
            <div className="hidden-desktop">
                {data.map((i) => (
                    <div key={i} className={styles.itemContainer}>
                        <div className={styles.contentItem}>
                            <Skeleton variant="text" width={70} height={15} animation="wave" />
                            <Skeleton variant="text" width={120} height={20} animation="wave" />
                            <div className={styles.detailItem}>
                                <div className={`column ${styles.columnLabel}`}>
                                    <Skeleton variant="text" width="80%" height={15} animation="wave" />
                                    <Skeleton variant="text" width="80%" height={15} animation="wave" />
                                    <Skeleton variant="text" width="80%" height={15} animation="wave" />
                                </div>
                                <div className={styles.detailContent}>
                                    <Skeleton variant="text" width="80%" height={15} animation="wave" />
                                    <Skeleton variant="text" width="80%" height={15} animation="wave" />
                                    <Skeleton variant="text" width="80%" height={15} animation="wave" />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="hidden-mobile">
                {data.map((i) => (
                    <Skeleton key={i} variant="text" width="100%" height={30} animation="wave" />
                ))}
                {data.map((i) => (
                    <Skeleton key={i} variant="text" width="100%" height={30} animation="wave" />
                ))}
            </div>
        </>
    );
};

const SkeletonLoader = () => {
    const styles = useStyles();
    return (
        <>
            <div className="row hidden-mobile">
                {/* <div className="col-lg-2">
                    <Skeleton animation="wave" variant="rect" height={300} width="100%" />
                </div> */}
                <div className="col-lg-10">
                    <Skeleton animation="wave" variant="rect" height={240} width="100%" style={{ marginBottom: 50 }} />
                </div>
            </div>
            <div className={classNames(styles.containerSkeleton, 'hidden-desktop')}>
                <Skeleton animation="wave" variant="rect" height={50} width="calc(100% - 100px)" style={{ marginLeft: 50, marginRight: 50 }} />
                <Skeleton animation="wave" variant="text" height={50} width="calc(100% - 100px)" style={{ marginLeft: 50, marginRight: 50 }} />
                <Skeleton animation="wave" variant="text" height={35} width="calc(100% - 100px)" style={{ marginLeft: 50, marginRight: 50 }} />
            </div>
        </>
    );
};

export default SkeletonLoader;
