import { makeStyles } from '@material-ui/core/styles';
import {
    GRAY_PRIMARY, PRIMARY, GRAY_LIGHT, BLACK, BLUE_LIGHT,
} from '@theme_color';
import { CreatePadding, FlexRow, FlexColumn } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    root: {
        ...FlexColumn,
        position: 'relative',
        background: GRAY_LIGHT,
        padding: '35px 40px',
        borderRadius: 10,
    },
    container: {
        marginTop: '20px',
        ...FlexColumn,
        display: 'flex',
        width: '100%',
        fontSize: '12px',
        padding: '0px 20px 40px 20px',
        backgroundColor: GRAY_LIGHT,
    },
    flexRow: {
        ...FlexRow,
    },
    content: {
        ...FlexRow,
        ...CreatePadding(0, 0, 70, 0),
        flexWrap: 'wrap',
    },
    colorPrimary: {
        color: PRIMARY,
    },
    appBar: {
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
        flexGrow: 1,
    },
    pageTitle: {
        fontWeight: 700,
        textAlign: 'center',
        color: PRIMARY,
        textTransform: 'uppercase',
        position: 'absolute',
        left: '50px',
        right: '50px',
    },
    wishlistWrapper: {
        // paddingTop: "50px"
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'left',
        background: GRAY_LIGHT,
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            position: 'unset',
        },
    },
    tableContainer: {
        boxShadow: 'none',
        background: GRAY_LIGHT,
    },
    tableOuterContainer: {
        paddingTop: 20,
    },
    table: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            borderTop: '0px',
        },
    },
    tableRowHead: {
        border: 'none !important',
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },
    tableRowResponsive: {
        [theme.breakpoints.down('sm')]: {
            display: 'grid !important',
            padding: 10,
        },
    },
    tableCellResponsive: {
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            border: 'none',
            padding: '8px 0',
        },
        color: BLACK,
    },
    tableCellResponsiveLast: {
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            padding: '8px 0',
        },
        color: BLACK,
    },
    tableBody: {
        backgroundColor: '#ffffff',
    },
    containerSkeleton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        marginTop: '30%',
    },
    mobLabel: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        width: '50%',
        minWidth: '150px',
        maxWidth: '200px',
        position: 'relative',
        paddingRight: 20,
        '&::after': {
            content: "':'",
            display: 'block',
            position: 'absolute',
            right: '8px',
            top: 0,
        },
    },
    pointTitle: {
        fontSize: '.75rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem',
        },
    },
    pointTitle2: {
        fontSize: '.75rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.25rem',
        },
    },
    blue: {
        backgroundColor: BLUE_LIGHT,
        color: 'white !important',
    },
    btnContainer: {
        textAlign: 'right',
    },
    btnAdd: {
        backgroundColor: BLUE_LIGHT,
        margin: '30px 0',
        '&:hover': {
            backgroundColor: BLUE_LIGHT,
        },
    },
    valueEdit: {
        color: BLUE_LIGHT,
        cursor: 'pointer',
    },
}));

export default useStyles;
