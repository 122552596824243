/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { useRouter } from 'next/router';
import CloseIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from './style';

const DraggableContent = (props) => {
    const {
        isLogin,
    } = props;
    const router = useRouter();
    const styles = useStyles();
    const [isDragging, setIsDragging] = useState(false);
    const [showPopup, setShowPopup] = useState(true);
    // const isDesktop = useMediaQuery('(min-width: 768px)');
    const [dragStartPos, setDragStartPos] = useState({ x: 0, y: 0 });

    const handleClick = () => {
        if (!isDragging) {
            router.push(isLogin && isLogin === 1 ? '/shopvaganza-coupon' : '/promo-shopvaganza');
        }
    };

    // When drag starts
    const handleStart = (e) => {
        setIsDragging(false);
        setDragStartPos({ x: e.clientX || e.touches[0].clientX, y: e.clientY || e.touches[0].clientY });
    };

    // When dragging
    const handleDrag = () => {
        setIsDragging(true);
    };

    // When drag stops
    const handleStop = (e) => {
        // if (!isDragging && !isDesktop) {
        //     router.push(isLogin && isLogin === 1 ? '/elektronik' : '/blog/promo-shopvaganza-2024'); // Only redirect if it wasn't a drag
        // }

        const dragEndPos = { x: e.clientX || e.changedTouches[0].clientX, y: e.clientY || e.changedTouches[0].clientY };
        const distanceMoved = Math.sqrt((dragEndPos.x - dragStartPos.x) ** 2 + (dragEndPos.y - dragStartPos.y) ** 2);

        if (distanceMoved < 10 && !e?.target?.classList?.contains('draggable-close')) {
            handleClick();
        }
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setShowPopup(false);
    };

    const dragBounds = {
        top: -500,
        bottom: 0,
        left: -150,
        right: 0,
    };

    return (
        <>
            {
                showPopup ? (
                    <Draggable
                        axis="both"
                        bounds={dragBounds}
                        onStart={handleStart}
                        onDrag={handleDrag}
                        onStop={handleStop}
                    >
                        <div className={styles.container}>
                            <div>
                                <div className={styles.iconWrapper}>
                                    <CloseIcon
                                        className={classNames(styles.icon, 'hidden-mobile', 'draggable-close')}
                                        onClick={handleClose}
                                    />
                                    <CloseIcon
                                        onTouchStart={handleClose}
                                        className={classNames(styles.icon, 'hidden-desktop', 'draggable-close')}
                                        onClick={handleClose}
                                    />
                                </div>
                                <img
                                    src="/assets/img/popupshopvaganza.gif"
                                    alt="icon-logo"
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                    }}
                                    className="draggable-image"
                                    onClick={handleClick}
                                />
                            </div>
                        </div>
                    </Draggable>
                ) : null
            }
        </>
    );
};

export default DraggableContent;
