/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import Thumbor from '@common_imageThumbor';
import Link from 'next/link';
import React from 'react';
// import { features } from '@config';
import classNames from 'classnames';
import useStyles from '../style';

// const imgUrl = (url) => (
//     `http://thumbor.sirclocdn.com/unsafe/186x186/filters:format(webp)/${url}`);

const ImageDetail = (props) => {
    const {
        handleClick, small_image, spesificProduct, name, url_key, imgProps = {},
        customGtmClass,
    } = props;
    const styles = useStyles();
    const src = spesificProduct?.image?.url || small_image.url_original;

    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link prefetch={false} href="/[...slug]" as={`/${url_key}`}>
            <a className={customGtmClass} onClick={handleClick}>
                {/* <img
                    src={imgUrl(src)}
                    onError={(e) => {
                        e.target.src = '/assets/img/placeholder.png';
                    }}
                    alt={small_image.label || name}
                    {...imgProps}
                    className={classNames(styles.img, imgProps.className)}
                    width={186}
                    height={186}
                /> */}
                <Thumbor
                    className={classNames(styles.img, imgProps.className)}
                    src={src}
                    alt={small_image.label || name}
                    width={186}
                    height={186}
                />
            </a>
        </Link>
    );
};

export default ImageDetail;
