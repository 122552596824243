import { makeStyles } from '@material-ui/core/styles';
import { GRAY_LIGHT } from '@theme_color';
import { FlexRow } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    infoTitle: {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.25rem',
        },
    },
    boxContainer: {
        background: GRAY_LIGHT,
        ...FlexRow,
        padding: 20,
        borderRadius: 10,
        marginTop: 30,
    },
    boxContainer2: {
        background: GRAY_LIGHT,
        [theme.breakpoints.up('sm')]: {
            ...FlexRow,
        },
        padding: 20,
        borderRadius: 10,
        marginTop: 30,
    },
    btnSave: {
        padding: '10px 20px',
        borderRadius: '5px',
        marginTop: 30,
    },
    skeletonContainer: {
        padding: '16px',
        width: '100%',
    },
    skeletonField: {
        marginBottom: '24px',
    },
    skeleton: {
        marginTop: '15px',
    },
}));

export default useStyles;
