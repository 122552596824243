import * as React from 'react';
const SvgNotification = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
        <path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 366" />
        <g data-name="Group 425">
            <path
                d="M12 3a.873.873 0 0 1 .9.844V5.6a5.172 5.172 0 0 1 4.5 4.993 8.971 8.971 0 0 0 1.439 5.24l.031.057a.42.42 0 0 1-.4.609H5.528a.42.42 0 0 1-.4-.609l.031-.057a8.972 8.972 0 0 0 1.439-5.24A5.172 5.172 0 0 1 11.103 5.6V3.844A.873.873 0 0 1 12 3Z"
                data-name="Path 380"
            />
            <path d="M14.25 18.75a2.25 2.25 0 1 1-4.5 0Z" data-name="Path 381" />
        </g>
    </svg>
);
export default SvgNotification;
