/* eslint-disable import/prefer-default-export */
export const sortAlphabetically = (arrayOfMenu) => {
    if (arrayOfMenu.length > 0) {
        const sortResult = arrayOfMenu.slice().sort((a, b) => (
            a.name.localeCompare(b.name)
        ));
        return sortResult;
    }
    return [];
};
