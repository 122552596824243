import * as React from 'react';
const SvgLocation = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" {...props}>
        <defs>
            <clipPath id="Location_svg__a">
                <path  d="M0 0h13.333v13.333H0z" data-name="Rectangle 356" />
            </clipPath>
        </defs>
        <path fill="none" d="M0 0h20v20H0z" data-name="Rectangle 357" />
        <g clipPath="url(#Location_svg__a)" data-name="Group 413" transform="translate(3.333 3.333)">
            <path
                fillRule="evenodd"
                d="M12 5.333a5.333 5.333 0 1 0-10.667 0c0 .1 0 .193.007.293.087 2.367 1.327 5.08 4.753 7.527a1 1 0 0 0 1.147 0c3.427-2.447 4.667-5.16 4.753-7.527.007-.099.007-.193.007-.293ZM8.08 6.747a2 2 0 1 0-1.414.586 2 2 0 0 0 1.414-.586Z"
                data-name="Path 373"
            />
        </g>
    </svg>
);
export default SvgLocation;
