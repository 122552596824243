import AlertView from './view';

const AlertPromo = (props) => {
    const {
        coupon, t, show, setShow, isLogin,
    } = props;
    React.useEffect(() => {
        if (coupon.length === 0 || !isLogin) {
            setShow(false);
        }
    }, [coupon]);
    return (
        <AlertView {...{ show, setShow }} data={coupon} t={t} />
    );
};

export default AlertPromo;
