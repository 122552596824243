import { SECONDARY, GRAY_25 } from '@src_theme/colors';
import { withStyles } from '@material-ui/core/styles';
import MUITabs from '@material-ui/core/Tabs';
import MUITab from '@material-ui/core/Tab';
import MUIAppBar from '@material-ui/core/AppBar';

const maxTabsInMobile = 4;
const tabsGap = '8px';

export const Tabs = withStyles({
    root: {
        minHeight: 0,
        '& .MuiTabs-indicator': {
            display: 'none',
        },
    },
    flexContainer: {
        gap: tabsGap,
        flexWrap: 'wrap',
    },
})(MUITabs);

export const Tab = withStyles({
    root: {
        textTransform: 'none',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: 1.1,
        padding: '8px 6px',
        flexBasis: `calc((100% / ${maxTabsInMobile}) - (${tabsGap} * 0.75))`,
        textAlign: 'center',
        minWidth: 0,
        // minHeight: 0,
        border: `1px solid ${SECONDARY}`,
        borderRadius: 4,
        opacity: 1,
        color: SECONDARY,
        minHeight: '46px',
        '&.Mui-selected': {
            backgroundColor: SECONDARY,
            color: GRAY_25,
            borderColor: 'transparent',
        },
        '@media (min-width: 768px)': {
            fontSize: 14,
            padding: '8px 12px',
            // width: 'auto',
            lineHeight: 1,
            flexBasis: 'auto',
            width: '110px',
            minWidth: '110px',
        },
        '@media (max-width: 370px)': {
            fontSize: 10,
        },
    },
})(MUITab);

export const AppBar = withStyles({
    root: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
    },
})(MUIAppBar);
