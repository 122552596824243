import { makeStyles } from '@material-ui/core/styles';
import {
    GRAY_PRIMARY, PRIMARY, GRAY_LIGHT, WHITE,
} from '@theme_color';
import {
    CreatePadding, FlexRow, FlexColumn,
} from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    root: {
        ...FlexColumn,
        position: 'relative',
        background: GRAY_LIGHT,
        padding: '35px 40px',
        borderRadius: 10,
    },
    content: {
        ...FlexRow,
        ...CreatePadding(0, 0, 70, 0),
        flexWrap: 'wrap',
    },
    colorPrimary: {
        color: PRIMARY,
    },
    appBar: {
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
        flexGrow: 1,
    },
    pageTitle: {
        fontWeight: 700,
        textAlign: 'center',
        color: PRIMARY,
        textTransform: 'uppercase',
        position: 'absolute',
        left: '50px',
        right: '50px',
    },
    wishlistWrapper: {
        // paddingTop: "50px"
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'left',
        background: GRAY_LIGHT,
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            position: 'unset',
        },
    },
    wishlistItems: {
        flex: '0 0 25%',
        padding: '5px',
        marginBottom: 35,
    },
    btnWishlist: {
        display: 'flex',
        height: '48px',
        minWidth: '175px',
        [theme.breakpoints.up('md')]: {
            width: 'fit-content',
        },
        justifyContent: 'center',
        padding: 8,
        borderRadius: '5px',
        textAlign: 'center',
        background: PRIMARY,
        color: WHITE,
        width: '100%',
        margin: 0,
    },
    containerSkeleton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        marginTop: '30%',
    },
    addAll: {
        margin: 0,
        lineHeight: '24px',
        fontSize: 14,
        textTransform: 'none',
    },
}));

export default useStyles;
