import * as React from 'react';
const SvgSearch = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <defs>
            <clipPath id="Search_svg__a">
                <path fill="#fcfcfd" d="M0 0h16v16H0z" data-name="Rectangle 364" />
            </clipPath>
        </defs>
        <rect width={32} height={32} fill="#2e3092" data-name="Rectangle 365" rx={16} />
        <g clipPath="url(#Search_svg__a)" data-name="Group 423" transform="translate(8 8)">
            <path
                fill="#fcfcfd"
                fillRule="evenodd"
                d="M10.301 11.416a6.306 6.306 0 1 1 1.115-1.115l4.226 4.226a.788.788 0 1 1-1.115 1.115Zm.826-4.982a4.7 4.7 0 1 1-4.7-4.7 4.7 4.7 0 0 1 4.7 4.7Z"
                data-name="Path 379"
            />
        </g>
    </svg>
);
export default SvgSearch;
