import { makeStyles } from '@material-ui/core/styles';
import { BLUE_LIGHT } from '@theme_color';
import {
    CreatePadding, CreateMargin, FlexColumn,
} from '@theme_mixins';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        ...FlexColumn,
        alignItems: 'left',
        ...CreatePadding(10, 50, 10, 10),
        '& .MuiInputBase-root': {
            border: '2px solid #000000',
            padding: '2px 10px',
            borderRadius: '4px',
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
        },
        '& .MuiInput-formControl': {
            marginTop: '0px',
        },
        '& .MuiInputLabel-formControl': {
            position: 'relative',
            color: 'black',
            fontWeight: 'bold',
            paddingBottom: 10,
        },
        '& .MuiButton-root': {
            borderRadius: 0,
        },
        '& .MuiInputLabel-asterisk': {
            color: 'red',
        },
    },
    fieldTips: {
        display: 'grid',
        gridTemplateColumns: '90% 10%',
        alignItems: 'center',
    },
    leftTitle: {
        marginLeft: '0px',
    },
    label: {
        position: 'relative',
        color: 'black',
        fontWeight: 'bold',
    },
    formControl: {
        width: '100%',
        height: '100%',
        ...CreateMargin(10, 0, 30, 0),
    },
    treeItem: {
        '& .MuiTreeItem-label': {
            width: 'fit-content',
            backgroundColor: 'transparent',
            cursor: 'default',
        },
        '& .MuiTreeItem-content': {
            width: 'fit-content',
            backgroundColor: 'transparent',
            alignItems: 'end',
            '&:hover': {
                backgroundColor: 'transparent',
                cursor: 'default',
            },
        },
        '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'transparent',
            cursor: 'default',
            '&:hover': {
                backgroundColor: 'transparent',
                cursor: 'default',
            },
        },
    },
    textField: {
        marginBottom: 0,
        height: 'unset',
    },
    subscribe: {
        marginTop: 0,
        marginBottom: '5px !important',
    },
    primaryCheck: {
        color: `${BLUE_LIGHT} !important`,
    },
    btnSave: {
        ...CreateMargin(30, 0, 30, 0),
        backgroundColor: BLUE_LIGHT,
        '&:hover': {
            backgroundColor: BLUE_LIGHT,
        },
    },
    divider: {
        marginTop: '5px',
        marginBottom: '20px',
    },
    footerText: {
        fontSize: 12,
    },
    toolTipsIcon: {
        color: BLUE_LIGHT,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        width: '2rem',
    },
}));

export default useStyles;
