/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@src_theme/icons/Notification';
import Router from 'next/router';

const Content = ({ withLink, totalUnread }) => (
    <div
        onClick={() => {
            if (withLink) {
                Router.push('/inboxnotification/notification');
            }
        }}
    >
        <Badge showZero badgeContent={totalUnread || 0}>
            <NotificationsIcon width={24} />
        </Badge>
    </div>
);

export default Content;
