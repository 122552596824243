/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

const vendorProductData = (vendorId) => `
  vendor_product_data(selectedStore: ${vendorId}) {
    vendor_id
    vendor_cost
    vendor_stock
    vendor_special_price
    flash_sale_price
    flash_sale_from
    flash_sale_to
    show_countdown
  }
`;

export const getLastViewedSchema = (config = {}) => gql`
    query getLastViewedProducts(
        $pageSize: Int!,
        $currentPage: Int!,
    ) {
        getLastViewedProducts(
            selectedStore: ${config.vendorId},
            pageSize: $pageSize,
            currentPage: $currentPage
        ) {
            page_info {
                current_page
                page_size
                total_pages
            }
            total_count
            items {
                ${vendorProductData(config.vendorId)}
                id
                weltpixel_labels {
                    categoryLabel {
                        css
                        customer_group
                        image
                        page_position
                        position
                        priority
                        text
                        text_padding
                        text_bg_color
                        text_font_size
                        text_font_color
                        __typename
                    }
                    productLabel {
                        css
                        customer_group
                        image
                        page_position
                        position
                        priority
                        text
                        text_padding
                        text_bg_color
                        text_font_size
                        text_font_color
                        __typename
                    }
                    __typename
                }
                name
                sku
                brand
                url_key
                small_image {
                    url
                    label
                    url_original
                    __typename
                }
                description {
                    html
                    __typename
                }
                short_description {
                    html
                    __typename
                }
                more_info {
                    label
                    value
                    __typename
                }
                price_range {
                    minimum_price {
                    discount {
                        amount_off
                        percent_off
                        __typename
                    }
                    final_price {
                        currency
                        value
                        __typename
                    }
                    fixed_product_taxes {
                        amount {
                        currency
                        value
                        __typename
                        }
                        label
                        __typename
                    }
                    regular_price {
                        currency
                        value
                        __typename
                    }
                    __typename
                    }
                    maximum_price {
                    discount {
                        amount_off
                        percent_off
                        __typename
                    }
                    final_price {
                        currency
                        value
                        __typename
                    }
                    fixed_product_taxes {
                        amount {
                        currency
                        value
                        __typename
                        }
                        label
                        __typename
                    }
                    regular_price {
                        currency
                        value
                        __typename
                    }
                    __typename
                    }
                    __typename
                }
            }
        }
    }
`;
